<template>
  <ul class="list--group" no-body v-show="!hideSuggestion">
    <div class="list--group-item" @click="suggestionClicked">
      <img src="@/assets/img/icons/pin-1.svg" alt="Location Pin" />
      <p>{{ suggestion.description }}</p>
    </div>
  </ul>
</template>

<script>
export default {
  name: 'place-sugestion',
  props: ['suggestion', 'hideSuggestion'],
  methods: {
    suggestionClicked() {
      this.$emit('suggestionClicked', this.suggestion);
    },
  },
};
</script>

<style scoped></style>
